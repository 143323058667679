import { graphql } from 'gatsby'
import { map, mapIndexed } from 'rambdax'
import PageLayout from '../components/PageLayout/PageLayout'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from '../zones/InlineWaitlist/InlineWaitlist'
import styles from './corporateComplianceCommittee.module.scss'
import classNames from 'classnames'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import TeamMember, {
  TeamMemberProps,
} from '@algbra/ui/components/TeamMember/TeamMember'
import { HAlign, VAlign } from '@algbra/ui/enums/align'
import MetaTags from '../components/MetaTags/MetaTags'
import { getMetaTags } from '@algbra/utils/metaTags'
import { get, head } from 'lodash'

type ZoneItemType = ZoneInlineWaitlistProps

export default function CorporateComplianceCommittee({
  pageContext,
  data,
  location,
}) {
  const zones: ZoneItemType[] = data.strapiGQL.corporatePage.contentZones
  const complianceCommittee = get(
    head(data.strapiGQL.teamMemberships),
    'compliance_committees'
  )
  const observers = get(head(data.strapiGQL.teamMemberships), 'observers')

  return (
    <PageLayout>
      <MetaTags {...getMetaTags(pageContext)} />

      <Whitespace
        as="header"
        type="lg"
        className={classNames(styles.header)}
        hasAnimation={false}
      >
        <Container role="complementary">
          <Heading level={1} type="xl" className={styles.title}>
            {pageContext.title}
          </Heading>
          <FreeText className={styles.summary} innerHTML={pageContext.subtitle}>
            {pageContext.subtitle}
          </FreeText>
        </Container>
      </Whitespace>

      <Container>
        <Row hAlign={HAlign.CENTER}>
          {mapIndexed(
            (node: TeamMemberProps, index) => (
              <Column size={6} sm={6} lg={6} key={`team-column-${index}`}>
                <div
                  style={{
                    maxWidth: 270,
                    ...(Number(index) % 2 === 0
                      ? { marginLeft: 'auto', marginRight: 20 }
                      : { marginRight: 'auto', marginLeft: 20 }),
                  }}
                >
                  <TeamMember hasDialog={true} {...node} />
                </div>
              </Column>
            ),
            complianceCommittee || []
          )}
        </Row>
      </Container>

      <div className={styles.observersContainer}>
        <h3 className={styles.title}>Observers</h3>
        <Container>
          <Row hAlign={HAlign.CENTER} vAlign={VAlign.CENTER}>
            {mapIndexed(
              (node: TeamMemberProps, index) => (
                <Column size={6} sm={4} lg={4} key={`team-column-${index}`}>
                  <div style={{ maxWidth: 270, margin: 'auto' }}>
                    <TeamMember hasDialog={true} {...node} />
                  </div>
                </Column>
              ),
              observers || []
            )}
          </Row>
        </Container>
      </div>

      {map(zone => {
        switch (zone.__typename) {
          case 'StrapiGQL_ComponentZonesInlineWaitlist':
            return <ZoneInlineWaitlist key={zone.id} {...zone} />
          default:
            return null
        }
      }, zones)}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      corporatePage(id: $id) {
        ...StrapiGQL_CorporatePagesWithContentZones
      }
      teamMemberships(publicationState: LIVE) {
        compliance_committees(sort: "complianceCommitteeOrder") {
          ...StrapiGQL_TeamFragment
        }
        observers(sort: "observersOrder") {
          ...StrapiGQL_TeamFragment
        }
      }
    }
  }
`
